import { Helmet } from 'react-helmet';
import Home from './components/pages/Home';
import { LanguageContextProvider } from './context/languageContext';
import React from 'react';
import { ThemeChangeContextProvider } from './context/themeChangeContext';
import myData from './db.json';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'

ReactGA.initialize("G-RQPN9X1X77");

const tagManagerArgs = {
  gtmId: 'GTM-K2QGQLMW'
}

TagManager.initialize(tagManagerArgs)


function App() {
  const {
    meta: { title },
  } = myData;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ThemeChangeContextProvider>
        <LanguageContextProvider>
          <Home />
        </LanguageContextProvider>
      </ThemeChangeContextProvider>
    </>
  );
}

export default App;
